@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: "GilroyRegular";
	src: local("GilroyRegular"), url(../assets/fonts/Regular.woff) format("woff");
}

@font-face {
	font-family: "GilroyExtraBold";
	src: local("GilroyExtraBold"), url(../assets/fonts/ExtraBold.otf) format("opentype");
}

@font-face {
	font-family: "GilroySemibold";
	src: local("GilroySemibold"), url(../assets/fonts/Semibold.woff) format("woff");
}

body {
	margin: 0;
	padding: 0;
	width: 100%;
	font-family: "Inter", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #121212;
}