:root {
	/* inputs */
	--input-gray-bg: #1c1c1c;
	--input-gray-border: #292929;
	--input-gray-border-focus: #313131;

	/* buttons */
	--button-gray-border: transparent;
	--button-gray-border-hover: transparent;
	--button-gray-bg: #3b3b3b;
	--button-gray-bg-hover: #313131;
	--button-gray-color: #ffffff;

	--button-grayOutline-border: #3b3b3b;
	--button-grayOutline-border-hover: #313131;
	--button-grayOutline-bg: transparent;
	--button-grayOutline-bg-hover: transparent;
	--button-grayOutline-color: #ffffff;

	--button-white-border: transparent;
	--button-white-border-hover: transparent;
	--button-white-bg: #eee;
	--button-white-bg-hover: #c0c0c0;
	--button-white-color: #000;

	--button-red-border: transparent;
	--button-red-border-hover: transparent;
	--button-red-bg: #cf3030;
	--button-red-bg-hover: #af2c2c;
	--button-red-color: #ffffff;

	--button-redOutline-border: #ce3636;
	--button-redOutline-border-hover: #ce3636;
	--button-redOutline-bg: transparent;
	--button-redOutline-bg-hover: rgba(239, 68, 68, 0.2);
	--button-redOutline-color: #ffffff;

	--button-green-border: transparent;
	--button-green-border-hover: transparent;
	--button-green-bg: #219642;
	--button-green-bg-hover: #1c7e38;
	--button-green-color: #ffffff;

	/* select */
	--select-primary-border: #0a0a0a;
	--select-primary-bg: #131313;
	--select-primary-bg-hover: #0f0f0f;
	--select-primary-color: #e5e7eb;
}